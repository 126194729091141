import React from "react";
import queryString from "query-string";
import { useMount } from "react-use";

const langToLocale = {
  en: "en",
  zh_cn: "zh-cn",
  zh_tw: "zh-hk",
};

const { id, lang } = queryString.parse(
  typeof window !== "undefined" ? window.location.search : "",
  {
    parseNumbers: true,
  }
);

const OldBlogRedirect = () => {
  useMount(() => {
    if (typeof window !== `undefined`) {
      if (id && lang) {
        const locale = langToLocale[lang];
        window.location.href = `/${locale}/blog/${id}`;
      } else {
        window.location.href = `/`;
      }
    }
  });
  return <></>;
};

export default OldBlogRedirect;
